<template>
  <!-- 入库管理 -->
  <div class="stockManagement">
    <!-- 搜索筛选 -->
    <FormSearch
      :getdata="getdata"
      :form-inline="formInline"
      :form-item-arr="formItemArr"
    >
      <template>
        <el-form-item
          label="仓库名称"
          prop="warehouseName"
        >
          <el-select
            v-model="formInline.warehouseName"
            class="cmpNameSearch"
            filterable
            remote
            reserve-keyword
            placeholder="请输入关键字搜索"
            :remote-method="warehouseNameremoteMethod"
          >
            <el-option
              v-for="item in warehouseNameArr"
              :key="item.warehouseName"
              :label="item.warehouseName"
              :value="item.warehouseName"
            />
          </el-select>
        </el-form-item>
      </template>
    </FormSearch>
    <!-- 表格  分页 -->
    <div class="page-container-table">
      <div class="list-operation">
        <el-button size="small" type="primary" @click="addRow">
          新增入库
        </el-button>
      </div>
      <Table :item-data="itemData" :list-data="listData" :loading="loading" :operation-button="operationButton" />
      <!-- 分页 -->
      <Pagination
        :params="formInline"
        :total="total"
        :in-article="listData.length"
        :get-data-list="getdata"
      />
    </div>
  </div>
</template>

<script>
import FormSearch from '@/components/FormSearch'
import Table from '@/components/Table'
import Pagination from '@/components/Pagination2'
import { timestampToTime } from '@/utils/util'
import { warehouseList, inventoryInPage } from '@/api/warehousingSystem'
export default {
  components: { FormSearch, Table, Pagination },
  data() {
    return {
      cmpNameArr: [],
      editFormData: {},
      itemData: [
        { label: '入库单号', prop: 'inNo', width: 180 },
        { label: '仓库名称', prop: 'warehouseName', width: 180 },
        { label: '入库来源', prop: 'type', width: 100, child: [{ dictId: '01', dictName: '普通入库' }, { dictId: '02', dictName: '代采入库' }, { dictId: '03', dictName: '集采入库' }] },
        { label: '合同编号', prop: 'contractNo', width: 100 },
        { label: '货主名称', prop: 'custName', width: 180 },
        { label: '入库日期', prop: 'inDate', width: 180 },
        { label: '入库经办人', prop: 'inReceiver', width: 160 },
        { label: '创建时间', prop: 'createTime', width: 160 }
      ],
      formItemArr: [
        { type: 'input', label: '货主名称', value: 'custName' },
        { type: 'input', label: '合同编号', value: 'contractNo' },
        { type: 'picker', label: '入库日期', value: 'inDateArr', startTimer: 'inDateStart', endTimer: 'inDateEnd' },
        { type: 'input', label: '入库经办人', value: 'inReceiver' }
      ],
      loading: false,
      operationButton: [
        { bType: 'primary', label: '详情', handleEvent: this.viewDetails }
      ],
      total: 0,
      listData: [],
      formInline: {
        pageSize: 10,
        pageNum: 1,
        inDateArr: []
      },
      warehouseNameArr: []
    }
  },
  mounted() {
    if (this.$route.query.inventoryNo) {
      this.formInline.inventoryNo = this.$route.query.inventoryNo
    }
    warehouseList('', res => {
      this.formItemArr.forEach((item) => {
        if (item.value === 'warehouseName') item.child = [...res.data]
      })
    })
  },
  methods: {
    // 获取列表数据
    getdata(type) {
      if (type === 'reset') {
        this.formInline = {
          pageSize: 10,
          pageNum: 1
        }
      } else if (!type) {
        this.formInline.pageNum = 1
      }
      inventoryInPage(this.formInline, res => {
        this.listData = [...res.data.pageData]
        this.listData.forEach(item => {
          item.createTime = timestampToTime(item.createTime)
        })
        this.total = res.data.total
      })
    },
    addRow() {
      this.$router.push({ path: '/warehousingSystem/stockStorage', query: { type: 'stockManagement' }})
    },
    // 联动搜索仓库列表
    warehouseNameremoteMethod(value) {
      warehouseList(value || '', res => {
        this.warehouseNameArr = res.data
      })
    },
    viewDetails(row) {
      this.$router.push({ path: '/warehousingSystem/stockDetails', query: { id: row.id }})
    }
  }
}
</script>

<style scoped>
.cmpNameSearch {
  width: 100%;
}
.marginTop {
  padding-top: 18px;
}
</style>
